













import { Component, Prop, Watch, Vue } from "vue-property-decorator";

@Component
export default class ErrorSnackbar extends Vue {
  color = "error";

  @Prop(Boolean) show!: boolean;
  @Prop(String) message!: string;

  isShowing = this.show;

  get showing(): boolean {
    return this.isShowing;
  }

  set showing(param: boolean) {
    this.isShowing = param;
  }
  @Watch("show")
  onShowChange(value: boolean) {
    this.showing = value;
  }
}
