





















































































import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import { SupabaseClient, User } from "@supabase/supabase-js";
import { SideBarGroup, SideBarItem, DiscordUser, UserLink } from "@/types";

@Component
export default class SideBar extends Vue {
  get supabase(): SupabaseClient {
    return this.$store.getters.supabase;
  }

  get user(): User {
    // @ts-expect-error
    return this.supabase.auth.user();
  }

  get userData(): DiscordUser {
    return this.$store.getters.getUserData;
  }

  get userLink(): UserLink {
    return this.$store.getters.getUserLink;
  }

  isShowing = true;
  selectedItem = -1;
  selectedSubItem = -1;

  sideBarItems: SideBarItem[] = [];

  sideBarGroups: SideBarGroup[] = [
    {
      name: "My Dashboard",
      icon: "mdi-view-dashboard",
      path: "/dashboard/me",
      items: [
        {
          name: "Credentials",
          icon: "mdi-cog",
          path: "/dashboard/me/credentials",
        },
      ],
    },
    // {
    //   name: "Alliance Dashboard",
    //   icon: "mdi-home-group",
    //   path: "/dashboard/alliance",
    //   items: [
    //     // { name: "Menus", icon: "mdi-menu", path: "/dashboard/alliance/menus" },
    //   ],
    // },
    // {
    //   name: "Server Dashboard",
    //   icon: "mdi-account-group",
    //   path: "/dashboard/server",
    //   items: [
    //     // { name: "Menus", icon: "mdi-menu", path: "/dashboard/server/menus" },
    //   ],
    // },
  ];

  async goto(path: string): Promise<void> {
    if (this.$route.path != path) {
      await this.$router.push({ path });
    }
  }

  @Prop(Boolean) value!: boolean;
  @Prop(Boolean) disabled!: boolean;

  mounted(): void {
    this.isShowing = this.value;
    let option;
    let index;
    for ([index, option] of Object.entries(this.sideBarItems)) {
      if (option.path === this.$route.path) {
        this.selectedItem = parseInt(index);
        break;
      }
      this.selectedItem = -1;
    }
    for ([index, option] of Object.entries(this.sideBarGroups)) {
      if (option.path === this.$route.path) {
        this.selectedItem = parseInt(index);
        for ([index, option] of Object.entries(option.items)) {
          if (option.path === this.$route.path) {
            this.selectedSubItem = parseInt(index);
            break;
          }
          this.selectedSubItem = -1;
        }
        break;
      }
      this.selectedItem = -1;
    }
  }

  @Watch("value")
  onValueChange(value: boolean): void {
    this.isShowing = value;
  }

  @Watch("isShowing")
  onIsShowingChange(val: boolean): void {
    this.$emit("input", val);
  }

  @Watch("$route.path", { immediate: true, deep: true })
  onPathChange(value: string): void {
    let option;
    let index;
    for ([index, option] of Object.entries(this.sideBarItems)) {
      if (option.path === this.$route.path) {
        this.selectedItem = parseInt(index);
        break;
      }
      this.selectedItem = -1;
    }
    for ([index, option] of Object.entries(this.sideBarGroups)) {
      if (option.path === this.$route.path) {
        this.selectedItem = parseInt(index) + this.sideBarGroups.length + 1;
        for ([index, option] of Object.entries(option.items)) {
          if (option.path === this.$route.path) {
            this.selectedSubItem = parseInt(index);
            break;
          }
          this.selectedSubItem = -1;
        }
        break;
      }
      this.selectedItem = -1;
    }
  }
}
