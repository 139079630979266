





































































import "vuex";
import "vue-router";
import "vuetify";

import { Vue, Component, Watch } from "vue-property-decorator";
import { SupabaseClient, User } from "@supabase/supabase-js";
import SideBar from "@/components/SideBar.vue";
import ErrorSnackbar from "@/components/ErrorSnackbar.vue";
import { Member, DiscordUser, UserLink } from "@/types";

@Component({
  components: {
    SideBar,
    ErrorSnackbar,
  },
})
export default class App extends Vue {
  get supabase(): SupabaseClient {
    return this.$store.getters.supabase;
  }

  get user(): User | null {
    return this.supabase.auth.user();
  }

  get errorCode(): string {
    return this.$route.params.errorCode;
  }

  get previousPath(): string {
    return this.$route.params.previousPath;
  }

  async signIn(): Promise<void> {
    await this.supabase.auth.signIn(
      {
        provider: "discord",
      },
      {
        // redirectTo: "http://localhost:8080",
        redirectTo: "https://rift.mrvillage.dev",
      }
    );
  }

  async signOut(): Promise<void> {
    await this.supabase.auth.signOut();
  }

  mounted() {
    console.log(this.$route.params);
  }

  sideBarOpen = !["xs"].includes(this.$vuetify.breakpoint.name);

  @Watch("user")
  async onUserChange(): Promise<void> {
    if (this.user) {
      if (this.errorCode == "103") {
        this.$router.replace({
          path: this.previousPath,
        });
      }
      const members = await this.supabase
        .from<Member>("cache_members")
        .select("id(name), guild(id, name, icon_url, owner_id), permissions")
        .eq("id", this.user.user_metadata["provider_id"]);
      const userLink = await this.supabase
        .from<UserLink>("users")
        .select("*")
        .eq("user_", this.user.user_metadata["provider_id"]);
      const user = await this.supabase
        .from<DiscordUser>("cache_users")
        .select("*")
        .eq("id", this.user.user_metadata["provider_id"]);
      this.$store.commit("setMembers", members.data);
      this.$store.commit("setUserLink", userLink.data ? userLink.data[0] : {});
      // @ts-expect-error
      this.$store.commit("setUserData", user.data[0]);
    } else {
      if (this.$route.path != "/") {
        this.$router.push("/");
      }
      this.$store.commit("clearMembers");
    }
  }
}
